<template>
	<div class="goodsDetails_page">
		<Banner :adCategory="4"></Banner>
		<div class="breadcrumb_box flex-center">
			<Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
		</div>
		<div class="content">
			<div class="goodsDetails flex">
				<img class="goodsImg" :src="formData.urls[0]" alt="" />
				<div class="goodsInfo">
					<div class="goodsName">{{ formData.productName }}</div>
					<div class="buyBtn display pointer" @click="buyBtn">立即购买</div>
				</div>
			</div>
			<div class="productDetails">
				<div class="name display">产品详情</div>
			</div>
			<div class="richText" v-html="formData.productDetails"></div>
		</div>
	</div>
</template>
<script>
import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
export default {
	components: {
		Banner,
		Breadcrumb,
	},
	data() {
		return {
			breadcrumbList: [
				{
					name: '产品中心',
					url: '',
				},
			],
			formData: {},
		}
	},
	created() {},
	mounted() {
		document.title = this.$route.query.goodsName + '—果尔佳'
		console.log(this.$route.query.goodsName)
		this.getData()
	},
	methods: {
		getData() {
			let params = {
				goodsId: this.$route.query.goodsId,
			}
			this.api.findGoodsDetails(params).then((res) => {
				this.formData = res.data
				this.breadcrumbList = [
					{
						name: '产品中心',
						url: '',
					},
					{
						name: res.data.classificationName,
						url: '',
					},
					{
						name: this.$route.query.goodsName,
						url: '',
					},
				]
			})
		},
		buyBtn() {
			let url =
				'https://www.golkia-mall.com/#/mall/goodsDetails?id=' + this.formData.id
			window.open(url, '_blank')
		},
	},
}
</script>
<style lang="scss" scoped>
.goodsDetails_page {
	.breadcrumb_box {
		background: #f4f5f5;
	}
	.goodsDetails {
		.goodsImg {
			width: 438px;
			height: 438px;
		}
		.goodsInfo {
			margin-left: 50px;
			padding-top: 40px;
			position: relative;
			.goodsName {
				font-size: 30px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 30px;
				margin-top: 20px;
			}
			.details {
				width: 649px;
				font-size: 14px;
				color: #666666;
				line-height: 24px;
				margin-top: 26px;
			}
			.buyBtn {
				width: 160px;
				height: 44px;
				background: #e62129;
				border: 1px solid #e62129;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				margin-top: 100px;
			}
		}
	}
	.productDetails {
		width: 1200px;
		height: 48px;
		background: #f4f5f5;
		padding-left: 30px;
		margin: 55px 0px;
		.name {
			width: 84px;
			height: 100%;
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #e62129;
			padding-top: 6px;
		}
	}
	.richText {
		width: 1111px;
		padding: 0px 30px;
		padding-bottom: 90px;
	}
}
</style>